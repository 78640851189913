import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

//***************************************************************************
//***************************************************************************
//***************************************************************************
// Rajout de fonction Jquery par Cyclo Pump
//***************************************************************************
//***************************************************************************
//***************************************************************************

//***************************************************************************
// DÉBUT gestion de la bannière d'annonce RGPD pour l'utilisation des cookies
//***************************************************************************

$(document).ready(
  function ($) {
    if ( $(location).attr("href").indexOf('CodePage=accueil') >= 0 ) {
      var CookieExiste = document.cookie.indexOf('bannierergpd=');
      if ( CookieExiste == -1 ) {
        document.cookie = "bannierergpd=oui";
        $("#BanniereCookie").foundation("open");
      }
    }
  }
);

$(document).ready(
  function ($) {
    if ( $(location).attr("href").indexOf('CodePage=accueil') >= 0 ) {
      var CookieExiste = document.cookie.indexOf('bannierergpd=');
      if ( CookieExiste == -1 ) {
        document.cookie = "bannierergpd=oui";
        $("#BanniereCookie2").foundation("open");
      }
    }
  }
);

$(document).on('closed.zf.reveal', '#BanniereCookie[data-reveal]', function() {
  /* Evenement déclenché à la fermeture des reveals : assure le bon fonctionnement
     des formulaires entre autre. */
//   location.reload(true);
});

$(document).on('closed.zf.reveal', '#BanniereCookie2[data-reveal]', function() {
  /* Evenement déclenché à la fermeture des reveals : assure le bon fonctionnement
     des formulaires entre autre. */
//   location.reload(true);
});
//***************************************************************************
// FIN gestion de la bannière d'annonce RGPD pour l'utilisation des cookies
//***************************************************************************


//***************************************************************************
// DÉBUT gestion du bouton Start
//***************************************************************************

$('#setcookiestart').on('click', function(e){
  document.cookie = "boutonstartactif=oui";
  var elem = document.getElementById("DivisionMereBoutonStart");
  elem.parentElement.removeChild(elem);
});

$(function() {

  $('div', '#MonTimer1')
    .hide()
    .stop();

  var BoutonStartActif = document.cookie.indexOf('boutonstartactif=');
  if ( BoutonStartActif != -1 ) {
    clearInterval(timer);
    return;
  }

  var SecondeAffichage = 10000;
  var FacteurMasquage = 3;

  var timer = setInterval(showDiv, SecondeAffichage);
  var counter = FacteurMasquage;

  function showDiv() {
    if (counter == 0) {
      counter++;
      return;
    }

    $('div', '#MonTimer1')
      .hide()
      .stop()
      .filter(function() {
        return this.id.match('div' + counter);
      })
      .show('fast');
    counter == FacteurMasquage ? counter = 0 : counter++;
  }
});

//***************************************************************************
// FIN gestion du bouton Start
//***************************************************************************


//***************************************************************************
// DÉBUT gestion du bouton Haut de page
//***************************************************************************

$(function() {
  
  $('div', '#MonIconeHautDePage')
    .hide();  
  
  $(window).scroll(function() {
    var SeuilDeclenchement = 100;
    var ObjetImageHaut = document.getElementById("BoutonHautDePage");
    if ( $(this).scrollTop() >= SeuilDeclenchement ) {
      $('div', '#MonIconeHautDePage')
        .show()
    }
    else  {
      $('div', '#MonIconeHautDePage')
        .hide()
    }
  });
});

//***************************************************************************
// FIN gestion du bouton Haut de page
//***************************************************************************


//***************************************************************************
// DÉBUT gestion des accordéons
//***************************************************************************

// Repliage automatique de tous les accordéons après chargement de la page
$(document).ready(function($) {
  $('.accordion').foundation('up', $('.accordion-content'), true);
});

//***************************************************************************
// FIN gestion des accordéons
//***************************************************************************


//***************************************************************************
// DÉBUT fonctions de traitement pour le paiement
//***************************************************************************

// DÉBUT fonction de génération automatique d'un TokenClient
$(document).ready(function ($) {
  var procstorage = require('./localstorage');
  var AchatExiste;
  var nomcle;
  var IdPaiement;

  var codtoken = new Uint32Array(1);

  $('.divPaiement').each( function () {

    IdPaiement = document.getElementById(this.id).querySelector('.aPaiement').id;
    nomcle = document.getElementById(this.id).getAttribute('CleStorage');
    AchatExiste = procstorage.lecture(nomcle);

    if ( AchatExiste == null ) {
      window.crypto.getRandomValues(codtoken);
      console.log("Nouveau client : écriture du nouveau TokenClient : '"+codtoken+"'.");
      procstorage.ecriture(nomcle, codtoken);
//      location.reload(true);
    }
    else {
      console.log("Token pré-existant : " + AchatExiste);
    };

    document.getElementById(IdPaiement).setAttribute('value', AchatExiste);
/* Empêchait (lors d'une remise à zéro/navigation privée) l'envoi du formulaire du premier coup : il fallait cliquer sur 'Submit' une seconde fois ---
     $('.formPaiement').submit(function(e) {
    $('.formPaiement').submit(function(e) {
      if ( AchatExiste == null ) {
        location.reload(true);
      }
    })
*/
  })
});
// FIN fonction de génération automatique d'un TokenClient

//***************************************************************************
// FIN fonctions de traitement pour le paiement
//***************************************************************************


//***************************************************************************
// DÉBUT fonctions de traitement pour les liens
//***************************************************************************

// DÉBUT fonction d'ajout du token client aux liens
$(document).ready(function ($) {
  $("[ciblelien]").each( function () {

    var procstorage = require('./localstorage');
    var NomChamp;
    var CodeTokenClient;
    var ContenuHref;

    NomChamp = this.getAttribute('CleStorage');
    CodeTokenClient = procstorage.lecture(NomChamp);
    ContenuHref = this.getAttribute('href');
    this.setAttribute('href', ContenuHref+CodeTokenClient);

  })
});
// FIN fonction d'ajout du token client aux liens

//***************************************************************************
// FIN fonctions de traitement pour les liens
//***************************************************************************


//***************************************************************************
// DÉBUT fonctions d'égalisation des hauteurs d'objets
//***************************************************************************

//equalize function
function equalHeight(group) {

  var tallest;
  var thisHeight;

  tallest = 0;
  group.each(function() {
    thisHeight = $(this).height();
    if(thisHeight > tallest) {
      tallest = thisHeight;
    }
  });
  group.height(tallest);
  group.reload();
};

$(document).ready(function ($) {
  //call the equalize height function
  equalHeight($("div.hauteur-egal"));
});

//***************************************************************************
// FIN fonctions d'égalisation des hauteurs d'objets
//***************************************************************************


//***************************************************************************
// DÉBUT fonctions de contraintes sur les champs de saisie
//***************************************************************************

function MaxNbreCaractere(monobj) {
  var Maximum = document.getElementById(monobj.id).getAttribute('maxcaractere');
  var MessageAlerte = document.getElementById(monobj.id).getAttribute('message');
  var ChaineLocal = document.getElementById(monobj.id).value;
  var NbreCaractere = ChaineLocal.length;

  if (NbreCaractere > (Maximum)) {
    ChaineLocal = ChaineLocal.slice(0, (Maximum));
    alert(Maximum+' '+MessageAlerte);
    document.getElementById(monobj.id).value = ChaineLocal;
  }
};

$('.ChampSaisie').on('keyup', function() {
  MaxNbreCaractere(this);
});

$('.ChampSaisie').on('change', function() {
  MaxNbreCaractere(this);
});

$('.ChampSaisie').keypress(
  function(event){
    if (event.which == '13') {
      event.preventDefault();
    }
});

$('.AlphaNumerique').keypress(
  function(event){
    const regex = new RegExp('([a-zA-Z0-9])');
    if (!(regex.test(event.key))) {
      event.preventDefault();
    }
});

//***************************************************************************
// FIN fonctions de contraintes sur les champs de saisie
//***************************************************************************


//***************************************************************************
// DÉBUT copie du code promo centralisé vers les formulaires de commande
//***************************************************************************

function RecopieChampSaisie(pSource, pCible) {
  var TexteARecopier = document.getElementById(pSource).value;

  $('.'+pCible).each( function() {
    document.getElementById(this.id).setAttribute('value', TexteARecopier);
  });

};

$("input[copiechamp]").on('keyup', function() {
  var Source = this.id;
  var Cible = this.getAttribute('copiechamp');

  RecopieChampSaisie(Source, Cible);
});

$("input[copiechamp]").on('change', function() {
  var Source = this.id;
  var Cible = this.getAttribute('copiechamp');

  RecopieChampSaisie(Source, Cible);
});

$(window).on('load', function() {
  $('input[copiechamp]').each( function () {

    var Source = this.id;
    var Cible = this.getAttribute('copiechamp');

    RecopieChampSaisie(Source, Cible);

  })
});

//***************************************************************************
// FIN copie du code promo centralisé vers les formulaires de commande
//***************************************************************************


//***************************************************************************
// DÉBUT fonctions d'affichage des images des boutons de formulaire
//***************************************************************************

$("input[imagesourisin]").on('mouseover', function() {
  var SourceImage = this.getAttribute('imagesourisin');
  document.getElementById(this.id).setAttribute('src', SourceImage);
});

$("input[imagesourisout]").on('mouseout', function() {
  var SourceImage = this.getAttribute('imagesourisout');
  document.getElementById(this.id).setAttribute('src', SourceImage);
});

//***************************************************************************
// FIN fonctions d'affichage des images des boutons de formulaire
//***************************************************************************


//***************************************************************************
// DÉBUT fonctions de masquage/affichage
//***************************************************************************

function Masquage() {
  $("[ciblemasquage]").show();
  $("[cibleaffichage]").hide();
};

function Affichage() {
  $("[ciblemasquage]").hide();
  $("[cibleaffichage]").show();
};

$(window).on('load', function() {
  $("[effacesirafraichi]").each( function () {
    document.getElementById(this.id).value = '' ;
  })
});

$("ul li a[href='#panel1']").on('click', function() {
  Masquage();
  $('.tabs-content .tabs-panel').removeClass("classeachat");
  $('.tabs-content .tabs-panel[id="panel1"]').addClass("classeachat");
});

$("ul li a[href='#panel2']").on('click', function() {
  Masquage();
  Affichage();
  equalHeight($("div.hauteur-egal"));
  $('.tabs-panel').removeClass("classeachat");
});

$('[name="tab-promotion"]').on('change.zf.tabs', function() {
  $("[effacesichangetab]").each( function () {
    document.getElementById(this.id).value = '' ;
  })
});

// DÉBUT contrôle du code de promotion Achat : ne doit pas être vide
$('input[name="submit"]').on('click', function() {

  if ($('[videinterdit]')) {
    var MaVar = this.getAttribute('src');
    var MaVar2 = document.getElementById('IdSourcePromoAchat');

    if(MaVar.indexOf('bouton-paiement') != -1) {
      if (MaVar2.value == '') {
        if ($(".tabs-content .tabs-panel").hasClass('classeachat')) {
          alert('Promotion Code is empty !');
          return false;
        }
      }
    }
  }

});
// FIN contrôle du champ du code de promotion : ne doit pas être vide

//***************************************************************************
// FIN fonctions de masquage/affichage
//***************************************************************************


//FIN_FICHIER._
